
import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Deposit {
  uid: string;
  date: string;
  txtid: string;
  amount: number;
  coin: string;
  name: string;
}

const Deposit: React.FC = () => {
  const [deposits, setDeposits] = useState<Deposit[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchDepositData = async () => {
      try {
        const response = await axios.get('https://api.xoxowin.com/getDepositData');
        const data = response.data;

        if (Array.isArray(data)) {
          const reversedData = data.reverse();
          setDeposits(reversedData);
        } else {
          console.error('Unexpected response structure:', data);
          setDeposits([]);
        }
      } catch (error) {
        console.error('Failed to fetch deposit data:', error);
        setDeposits([]);
      }
    };

    fetchDepositData();
  }, []);

  const filteredDepositData = searchTerm
    ? deposits.filter((deposit: { uid: any; }) => String(deposit.uid).includes(searchTerm))
    : deposits;

  return (
    <div className='ml-0 lg:ml-[256px]'>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Search by UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-1 border rounded w-full"
        />
      </div>
      <div className="overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Transaction Id</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Coin</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepositData.map((deposit: { uid: any; name: any; date: any; txtid: any; amount: any; coin: any; }, index: number) => (
              <tr key={index + 1}>
                <td className="py-1 px-2 border-b border-gray-200">{index + 1}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.uid}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.name}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.date}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.txtid}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.amount}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.coin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Deposit;
